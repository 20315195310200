@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/Fonts/assistant-regular.woff2') format('woff2'),
    url('./Assets/Fonts/assistant-regular.woff') format('woff'),
    url('./Assets/Fonts/assistant-regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Assistant Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/Fonts/assistant-semi-bold.woff2') format('woff2'),
    url('./Assets/Fonts/assistant-semi-bold.woff') format('woff'),
    url('./Assets/Fonts/assistant-semi-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Assistant Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/Fonts/assistant-bold.woff2') format('woff2'),
    url('./Assets/Fonts/assistant-bold.woff') format('woff'),
    url('./Assets/Fonts/assistant-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/Fonts/Roboto-Regular.woff2') format('woff2'),
    url('./Assets/Fonts/Roboto-Regular.woff') format('woff'),
    url('./Assets/Fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/Fonts/Roboto-Medium.woff2') format('woff2'),
    url('./Assets/Fonts/Roboto-Medium.woff') format('woff'),
    url('./Assets/Fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./Assets/Fonts/Roboto-Bold.woff2') format('woff2'),
    url('./Assets/Fonts/Roboto-Bold.woff') format('woff'),
    url('./Assets/Fonts/Roboto-Bold.ttf') format('truetype');
}